<template>
  <section id="service" class="service" v-if="loaded && clinic">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row" >
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>
              <span class="d-md-inline d-none">
              <router-link :to="{ name: 'SuperAdminClinics' }">{{ $t('clinics') }}</router-link>
            </span>
              <span class="d-md-inline d-none"> / </span>{{ clinic.name }}
            </h1>
          </div>
          <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
            <SearchTable/>
          </div>
          <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
            <DataTableFilterButton/>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator">
            <AddObject module="superadmin"/>
          </div>
        </div>
      </div>
    </transition>
    <div class="sub-menu no-print">
      <template v-for="(m, mind) in menu">
        <router-link :class="{ 'router-link-active': $route.name === 'SuperAdminPatientNotifications' && m.to === 'SuperAdminClinicPatients' }"
                     v-if="!m.hide_for_tech || notTechSpecialist"
                     :to="{ name: m.to }"
                     :key="`warehouse-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>
    <router-view/>
  </section>
</template>

<script>
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import AddObject from "@/components/parts/datatable/AddObject"
import SearchTable from "@/components/parts/datatable/SearchTable"
import { mapState } from "vuex"

export default {
  name: "SuperAdminClinicPage",
  components: {
    DataTableFilterButton,
    AddObject,
    SearchTable,
  },
  data() {
    return {
      loaded: false,
      menu: [
        { to: 'SuperAdminClinicGeneral', title: 'general' },
        { to: 'SuperAdminClinicRights', title: 'rights' },
        { to: 'SuperAdminClinicCategories', title: 'categories' },
        { to: 'SuperAdminClinicServices', title: 'services' },
        { to: 'SuperAdminClinicPrices', title: 'prices_price' },
        { to: 'SuperAdminClinicDoctors', title: 'doctors' },
        { to: 'SuperAdminClinicSuperManagers', title: 'superadministrators' },
        { to: 'SuperAdminClinicManagers', title: 'administrators' },
        { to: 'SuperAdminClinicAssistants', title: 'assistants' },
        { to: 'SuperAdminClinicTechnics', title: 'technics' },
        { to: 'SuperAdminClinicPatients', title: 'patients', hide_for_tech: true },
        // { to: 'SuperAdminClinicPayments', title: 'payments' },
        { to: 'SuperAdminClinicTariffs', title: 'tariff' },
        { to: 'SuperAdminClinicBranches', title: 'branches', hide_for_tech: true },
        { to: 'SuperAdminClinicImport', title: 'patients_import' },
        { to: 'SuperAdminClinicServicesImport', title: 'services_import' },
        { to: 'SuperAdminClinicIntegrations', title: 'integrations' },
        { to: 'SuperAdminClinicMarketingNotifications', title: 'notifications' },
        { to: 'SuperAdminClinicAgreementTemplates', title: 'agreements' },
        { to: 'SuperAdminClinicContractTemplates', title: 'contracts' },
        { to: 'SuperAdminClinicLogs', title: 'logs' },
      ]
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      role: state => state.auth.user.role,
    }),
    notTechSpecialist() {
      return this.role !== 'tech_specialist'
    }
  },
  async mounted() {
    const clinic = await this.$store.dispatch('getSuperAdminClinic', this.$route.params.id)
    this.$store.commit('setClinic', { clinic })
    this.$store.commit('setBranches', clinic.branches)
    if(clinic.currency) {
      window.localStorage.setItem('currency', clinic.currency.name)
    }
    this.loaded = true
  },
  unmounted() {
    this.$store.commit('setClinic', { clinic: null })
    window.localStorage.removeItem('currency')
  }
}
</script>
