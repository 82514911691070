import store from "@/store"
import { getCountryNumber } from "@/services/country.helper"
import { padString } from "@/services/text.service"

const UrlServer = "http://localhost:5893"; // HTTP адрес сервера торгового оборудования, если пусто то локальный вызов
const User = "";      // Пользователь доступа к серверу торгового оборудования
const Password = "";  // Пароль доступа к серверу торгового оборудования

const KKMExecuteCommand = async (params) => {

	params.IdCommand = guid()
	const settings = JSON.parse(store?.state?.auth?.clinic?.ppo_settings || '{}')
	const branchId = store?.state?.auth?.branch || '0'
	const cashierName = settings[`kkm_cashier_name${branchId}`] || ''
	const cashierINN = settings[`kkm_cashier_inn${branchId}`] || ''
	params.CashierName = cashierName
	params.CashierVATIN = cashierINN

	const url = `${UrlServer}/Execute`;
	const basicAuth = (User !== '' || Password !== '') ? ('Basic ' + btoa(User + ':' + Password)) : '';
	const response = await fetch(
			url,
			{
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				//credentials: 'same-origin', // include, *same-origin, omit
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
					'Authorization': basicAuth
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
				body: JSON.stringify(params)
			}
	)
	const jsonRes = await response.json()
	const status = jsonRes.Status
	if (status === 0) { // 0 - ok, 1 - Выполняется, 2 - Ошибка, 3 - Данные не найдены
		return { data: jsonRes, error: null }
	}
	return { data: jsonRes, error: jsonRes.Error || jsonRes.Warning }
}

function guid() {
	function S4() {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	}
	return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export const KKMService = {
	async openShift() {
		return await KKMExecuteCommand({
			Command: "OpenShift",
			// ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
			// Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
			InnKkm: "",
			// Система налогообложения (СНО) для поиска ККТ, Можно не указывать, или = "" - любое СНО
			TaxVariant: "",
			// Номер устройства. Если 0 то первое не блокированное на сервере
			// Не рекомендовано для использования.
			// Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
			NumDevice: 0,
			// Id устройства. Строка. Если = "" то первое не блокированное на сервере
			IdDevice: "",
			// Не печатать чек на бумагу
			NotPrint: false,
		})
	},
	async closeShift() {
		return await KKMExecuteCommand({
			Command: "CloseShift",
			// ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
			// Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
			InnKkm: "",
			// Система налогообложения (СНО) для поиска ККТ, Можно не указывать, или = "" - любое СНО
			TaxVariant: "",
			// Номер устройства. Если 0 то первое не блокированное на сервере
			// Не рекомендовано для использования.
			// Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
			NumDevice: 0,
			// Id устройства. Строка. Если = "" то первое не блокированное на сервере
			IdDevice: "",
			// Не печатать чек на бумагу
			NotPrint: false,
		})
	},
	async printXReport() {
		return await KKMExecuteCommand({
			Command: "XReport",
			// ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
			// Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
			InnKkm: "",
			// Система налогообложения (СНО) для поиска ККТ, Можно не указывать, или = "" - любое СНО
			TaxVariant: "",
			// Номер устройства. Если 0 то первое не блокированное на сервере
			// Не рекомендовано для использования.
			// Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
			NumDevice: 0,
		})
	},
	async depositToCashdesk(amount) {
		return await KKMExecuteCommand({
			Command: "DepositingCash",
			// ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
			// Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
			InnKkm: "",
			// Система налогообложения (СНО) для поиска ККТ, Можно не указывать, или = "" - любое СНО
			TaxVariant: "",
			// Номер устройства. Если 0 то первое не блокированное на сервере
			// Не рекомендовано для использования.
			// Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
			NumDevice: 0,
			// Сумма внесения наличных
			// Сумма внесения наличных
			Amount: amount,
		})
	},
	async withdrawFromCashdesk(amount) {
		return await KKMExecuteCommand({
			Command: "PaymentCash",
			// ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
			// Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
			InnKkm: "",
			// Система налогообложения (СНО) для поиска ККТ, Можно не указывать, или = "" - любое СНО
			TaxVariant: "",
			// Номер устройства. Если 0 то первое не блокированное на сервере
			// Не рекомендовано для использования.
			// Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
			NumDevice: 0,
			// Сумма внесения наличных
			// Сумма внесения наличных
			Amount: amount,
		})
	},
	async printCheque({ payId, amount, payMethod, services, totalPrice, totalPaid, numDevice }) {
		const recordFullyPaid = totalPaid >= totalPrice
		const isPartialPayment = amount < totalPrice
		const isAdvance = isPartialPayment && !recordFullyPaid
		const previousAdvances = recordFullyPaid && isPartialPayment ? totalPaid - amount : 0
		const settings = JSON.parse(store?.state?.auth?.clinic?.ppo_settings || '{}')
		const branchId = store?.state?.auth?.branch || '0'
		const countryId = store?.state?.auth?.clinic?.country_id || '3'
		const countryNumber = getCountryNumber(countryId)
		const cashMethods = settings[`kkm_cashmethods${branchId}`] || ''
		let serviceName = settings[`kkm_service${branchId}`] || ''
		if(isAdvance) serviceName = `Аванс, ${serviceName}`
		const clinicName = settings[`kkm_clinic_name${branchId}`] || ''
		const sendServices = (settings[`kkm_send_services${branchId}`] || 0) == '1'
		const taxMode = settings[`kkm_vat${branchId}`] || ''
		const cashAmount = cashMethods.includes(payMethod) ? amount : 0
		const nonCashAmount = amount - cashAmount
		const rows = [ // Строки чека
			{
				PrintText: {
					Text: ">#2#<"+clinicName, //При вставке в текст символов ">#10#<" строка при печати выровнеется по центру, где 10 - это на сколько меньше станет строка ККТ
					Font: 1,
				},
			},
			{ PrintText: { Text: "<<->>" }, },
			{ PrintText: { Text: "<<*>>" }, }
		]
		if(!sendServices && previousAdvances > 0 && recordFullyPaid) {
			amount = totalPaid
		}
		if(!sendServices || !recordFullyPaid) {
			rows.push(addChequeLine(serviceName, amount, 1, amount, taxMode, countryNumber, isAdvance))
		}
		if(sendServices && recordFullyPaid) {
			let lineByLineAmount = 0
			let generalDiscountCoefficient = 1
			const servicesArray = Object.values(services)
			servicesArray.forEach((service) => {
				const servicePrice = priceOfService(service)
				lineByLineAmount += Math.round(servicePrice * service.teeth.length * 100) / 100
			})
			if(lineByLineAmount > totalPrice) {
				generalDiscountCoefficient = totalPrice / lineByLineAmount
			}
			lineByLineAmount = 0
			servicesArray.forEach((service) => {
				const quantity = service.teeth.length
				const priceOriginal = Number(service.pivot.price_original)
				const price = priceOfService(service)
				const discountedAmount = Math.round(price * quantity * 100 * generalDiscountCoefficient) / 100
				lineByLineAmount += discountedAmount
				rows.push(addChequeLine((service.cod ? `[${service.cod}] ` : '') + service.name, priceOriginal, quantity, discountedAmount, taxMode, countryNumber, isAdvance))
			})
			if(lineByLineAmount != totalPrice) { // because of rounding
				const adjustment = (Math.round((totalPrice - lineByLineAmount) * 100) / 100)
				rows[(rows.length - 1)].Register.Amount += adjustment
				if(rows[(rows.length - 1)].Register.Amount === null) {
					rows[(rows.length - 1)].Register.Amount = 0
				}
			}
		}
		const data = {
			Command: "RegisterCheck",
			InnKkm: "",
			TaxVariant: "",
			NumDevice: numDevice || 0,
			IsFiscalCheck: true,
			TypeCheck: 0, // 0 – продажа/приход; 1 – возврат продажи/прихода; 2 – корректировка продажи/прихода; 3 – корректировка возврата продажи/прихода; (>=ФФД 1.1); 10 – покупка/расход; 11 - возврат покупки/расхода; 12 – корректировка покупки/расхода; 13 – корректировка возврата покупки/расхода; (>=ФФД 1.1)
			NotPrint: false, // Не печатать чек на бумагу
			NumberCopies: 0,  // Количество копий документа
			ClientAddress: "", // Телефон или е-Майл покупателя, Тег ОФД 1008, Если чек не печатается (NotPrint = true) то указывать обязательно, Формат: Телефон +{Ц} или Email {С}@{C}
			ClientInfo: "", // Покупатель (клиент) - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя(клиента). Тег 1227
			ClientINN: "", // ИНН покупателя (клиента). Тег 1228
			SenderEmail: "", // Aдрес электронной почты отправителя чека, Тег ОФД 1117 (если задан при регистрации можно не указывать)
			AddressSettle: "", // Адрес расчетов, Тег ОФД 1009 (если не задано - берется из регистрационных данных ККТ)
			PlaceMarket: "", // Место расчетов, Тег ОФД 1187 (если не задано - берется из регистрационных данных ККТ)
			PayByProcessing: false, //В тестовом чеке автоматический эквайринг выключен
			NumDeviceByProcessing : null, // Номер устройства для эквайринга - Null - из настроек на сервере, 0 - любое, число - номер конкретного устройства
			ReceiptNumber: payId, // Номер чека для эквайринга
			PrintSlipAfterCheck: false, // Печатать Слип-чек после чека (а не в чеке)
			PrintSlipForCashier: true, // Печатать Слип-чек дополнительно для кассира (основной слип-чек уже будет печататся в составе чека)
			UserAttribute: { // Дополнительный реквизит пользователя тег ОФД 1084
				Name: "", // Наименование дополнительного реквизита пользователя тег ОД 1085
				Value: "" // Значение дополнительного реквизита пользователя тег ОФД 1086
			},
			AdditionalAttribute: "", // Дополнительный реквизит чека тег 1192
			CheckStrings: rows, // Строки чека
			Cash: cashAmount, // Наличная оплата (2 знака после запятой), Тег 1031
			ElectronicPayment: nonCashAmount, // Сумма электронной оплаты (2 знака после запятой), Тег 1081
			AdvancePayment: previousAdvances, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой), Тег 1215
			Credit: 0, // Сумма постоплатой(в кредит) (2 знака после запятой), Тег 1216
			CashProvision: 0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой), Тег 1217
			// Только для чеков коррекции
			// CorrectionType: 1, // Тип коррекции 0 - самостоятельно 1 - по предписанию, Тег 1173, Только для чеков коррекции!
			// Дата документа основания для коррекции, Тег ОФД 1178, Только для чеков коррекции!
			// CorrectionBaseDate: '2017-06-21T15:30:45',
			// Номер документа основания для коррекции, Тег ОФД 1179, Только для чеков коррекции!
			// CorrectionBaseNumber: "MOS-4516",
		}
		// console.log(data)
		return await KKMExecuteCommand(data);
	},
	async getKKMInfo() {
		return await KKMExecuteCommand({
			Command: "GetDataKKT",
			// ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
			// Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
			InnKkm: "",
			// Система налогообложения (СНО) для поиска ККТ, Можно не указывать, или = "" - любое СНО
			TaxVariant: "",
			// Номер устройства. Если 0 то первое не блокированное на сервере
			// Не рекомендовано для использования.
			// Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
			NumDevice: 0
		});
	},
	async payByPaymentCard(amount, numDevice) {
		return await KKMExecuteCommand({
			Command: "PayByPaymentCard",
			// ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
			// Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
			InnKkm: "",
			// Система налогообложения (СНО) для поиска ККТ, Можно не указывать, или = "" - любое СНО
			TaxVariant: "",
			// Номер устройства. Если 0 то первое не блокированное на сервере
			// Не рекомендовано для использования.
			// Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
			NumDevice: numDevice || 0,
			Amount: amount,
		});
	},
	async getDevices() {
		return await KKMExecuteCommand({
			Command: "List",
			Active: true,
		});
	}
}

function addChequeLine(name, amount, quantity, discountedAmount, taxMode, countryNumber, isAdvance = false) {
	return {
		Register: {
			Name: padString(name, 64), // Наименование товара 64 символа, Тег 1059
			Quantity: quantity || 0, // Количество товара (3 знака после запятой), Тег 1023
			Price: amount || 0, // Цена за шт. без скидки (2 знака после запятой)
			Amount: discountedAmount || 0, // Конечная сумма строки с учетом всех скидок /наценок; (2 знака после запятой), Из нее расчет тега 1079
			Department: 0, // Отдел, по которому ведется продажа
			Tax: taxMode, // НДС в процентах или ТЕГ НДС: 0 (НДС 0%), 10 (НДС 10%), 20 (НДС 20%), -1 (НДС не облагается), 120 (НДС 20 /120), 110 (НДС 10 /110), Тег 1043, Из нее расчет тега 1079
			SignMethodCalculation: isAdvance ? 3 : 4, // Признак способа расчета. Тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле, 3: "АВАНС", 4: "ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)", 5: "ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
			SignCalculationObject: 4, // Признак предмета расчета. Тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле, 4 - "УСЛУГА (наименование и иные сведения, описывающие услугу)
			MeasureOfQuantity: 0, // 0 шт.или ед.; 10 г; 11 кг; 12 т; 20 см; 21 дм; 22 м; 30 кв.см; 31 кв.дм; 32 кв.м; 40	мл; 41 л; 42 куб.м; 50 кВт ч; 51 Гкал; 70 сутки; 71 час; 72 мин; 73 с; 80 Кбайт; 81 Мбайт; 82 Гбайт; 83 Тбайт; 255 Прочее
			PackageQuantity: null, // Общее количество товара в упаковке(тег 1294) Передавать ТОЛЬКО при продаже товара из упаковки маркировки!!
			CountryOfOrigin: countryNumber, // Цифровой код страны происхождения товара в соответствии с Общероссийским классификатором стран мира 3 симв. Тег 1230
			CustomsDeclaration: "" , // Регистрационный номер таможенной декларации 32 симв. Тег 1231
			ExciseAmount: null, // Сумма акциза с учетом копеек, включенная в стоимость предмета расчета Тег 1229, Иначе сумма акциза
			AdditionalAttribute: "", // Дополнительный реквизит предмета расчета тег 1191, Только для ФФД 1.1 !
		},
	}
}

function priceOfService(service) {
	let servicePrice = service.pivot.price
	if(servicePrice === undefined) {
		const serviceDiscount = service.pivot.discount_type === 'percent' ? ((service.pivot.discount / 100) * service.pivot.price_original) : service.pivot.discount
		servicePrice = Number(service.pivot.price_original) - serviceDiscount
	}
	return Math.round(servicePrice * 100) / 100
}
